import { Col, Container, ListGroup, Row } from "react-bootstrap";

export default function Contact() {
  return (
    <Container>
      <Row className="mb-5">
        <Col xs={12} className="my-5">
          <h2 className="mb-5">Contact Page</h2>
          <ListGroup className="d-inline-flex">
            <ListGroup.Item>
              <b>Address:</b>{" "}
              <span>86-90 Paul Street, London, England, EC2A 4NE</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Email:</b>{" "}
              <a href="mailto:hello@callshield.ai">hello@callshield.ai</a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}
