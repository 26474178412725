import gql from "graphql-tag";

export const PHONECALL_UPDATES = gql`
  subscription {
    phonecallUpdated {
      id
      createdDateTime
      createdByUserId
      eventDateTime
      dialledPartyNumber
      callingPartyNumber
      dialledAgent
      callingAgent
      source
      callDirection
      sentiment
      status
      transcription
      keyFacts
      topics
    }
  }
`;
