import React, { useState, useContext, createContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {
  sendAmplitudeData,
  setAmplitudeUserId,
} from "../services/amplitudeClient";
import config from "../config";

interface AuthContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  logout: () => void;
  user: any;
}

interface Props {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  loading: true,
  logout: () => {},
  user: null,
});

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }: Props) {
  const { isAuthenticated, isLoading, user, logout: auth0Logout } = useAuth0();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated && user) {
        // You can also send the user data to amplitude for tracking
        setAmplitudeUserId(user.sub);
        //navigate("/dashboard");
      }
      setLoading(false);
    }
  }, [isAuthenticated, isLoading, user, navigate]);

  const logout = async () => {
    setLoading(true);
    sendAmplitudeData("user-logout", {});
    await auth0Logout({
      logoutParams: {
        returnTo: config.AUTH_REDIRECT_URI,
      },
    });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
}

export function RequireAuth({ children }) {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="m-5 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading auth</span>
        </Spinner>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
