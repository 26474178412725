import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAuth } from "../contexts/Auth";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import config from "../config";
import { useDarkMode } from "./useDarkMode";

function Header() {
  const { user, logout } = useAuth();
  const { loginWithRedirect } = useAuth0();

  const { isDarkMode, toggle: toggleDarkMode } = useDarkMode();

  const navigate = useNavigate();

  useEffect(() => {
    const htmlElm = document.querySelector("html");
    htmlElm.setAttribute("data-bs-theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <Navbar expand="lg" className="shadow-lg py-3">
      <Container>
        <Link className="navbar-brand" to="/">
          <i className="bi bi-shield-shaded me-1"></i>
          <span>CallShield</span>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" role="a">
          <Nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            {!user && (
              <>
                <Nav.Item>
                  <Nav.Link as="button" onClick={() => navigate("contact")}>
                    Contact
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as="button"
                    onClick={() =>
                      loginWithRedirect({
                        appState: {
                          redirect_uri: config.AUTH_REDIRECT_URI,
                        },
                      })
                    }
                  >
                    Login
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {user && (
              <>
                <Nav.Item>
                  <Nav.Link as="button" onClick={() => navigate("dashboard")}>
                    Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as="button"
                    onClick={() => navigate("/report/sentiment")}
                  >
                    Reports
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as="button" onClick={() => navigate("contact")}>
                    Contact
                  </Nav.Link>
                </Nav.Item>
                <NavDropdown
                  id="nav-dropdown"
                  className="dropdown-no-arrow"
                  align="end"
                  title={<i className="bi-person-fill"></i>}
                >
                  <NavDropdown.Item href="" disabled={true}>
                    My Account
                  </NavDropdown.Item>
                  <NavDropdown.Item href="" disabled={true}>
                    Settings
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as="button" onClick={logout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            <Nav.Item>
              <div className="vr d-none d-lg-flex h-100 mx-lg-2"></div>
              <hr className="d-lg-none my-2" />
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as="button" onClick={toggleDarkMode}>
                {isDarkMode ? (
                  <i className="bi-moon-stars-fill"></i>
                ) : (
                  <i className="bi-sun-fill"></i>
                )}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
