import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Popover from "react-bootstrap/Popover";

import { useLocation, useNavigate } from "react-router-dom";
import { sendAmplitudeData } from "../services/amplitudeClient";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { PHONECALL_UPDATES } from "../subscriptions/phonecall";
import { useAuth } from "../contexts/Auth";
import { parseISO, isValid } from "date-fns";
import { UsersTable } from "./UsersTable";
import { SentimentValue, SentimentValueInfo } from "./SentimentValueIcon";
import { CallsTable } from "./CallsTable";
import { useLocalStorage } from "usehooks-ts";

import { useQuery } from "@apollo/client";
import { GET_CONTACT_TYPES } from "../queries/Calls";

export const TAB_KEYS = {
  users: "#users",
  phonecalls: "#phonecalls",
} as const;

export type TabsKeys = (typeof TAB_KEYS)[keyof typeof TAB_KEYS];

const TAB_KEYS_VALUES = new Set<string>([...Object.values(TAB_KEYS)]);

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();

  useSubscription(PHONECALL_UPDATES);
  const auth = useAuth();

  const { loading: contactTypesLoading, data: contactTypesData } =
    useQuery(GET_CONTACT_TYPES);

  const [currentContactType, setCurrentContactType] = useState(null);

  const [search, setSearch] = useLocalStorage("search", "");
  const [startDate, setStartDate] = useLocalStorage("startDate", "");
  const [endDate, setEndDate] = useLocalStorage("endDate", "");

  const [currentTab, setCurrentTab] = useState<TabsKeys>(TAB_KEYS.users);

  useEffect(() => {
    const locationHash = location.hash;

    sendAmplitudeData("dashboard", {});

    if (TAB_KEYS_VALUES.has(locationHash)) {
      setCurrentTab(locationHash as TabsKeys);
    } else {
      setCurrentTab("#phonecalls");
    }
  }, [location]);

  const [filterBookmarked, setFilterBookmarked] = useLocalStorage(
    "filterBookmarked",
    false,
  );

  const [filterSentimentValue, setFilterSentimentValue] = useLocalStorage(
    "filterSentimentValue",
    [],
  );

  const handleSetSearch = (search) => {
    sendAmplitudeData("dashboard-search", { query: search });
    setSearch(search);
  };

  const handleSetStartDate = (date) => {
    if (isValidDate(date))
      sendAmplitudeData("dashboard-date-start", { date: date });
    setStartDate(date.target.value);
  };

  const handleSetEndDate = (date) => {
    if (isValidDate(date))
      sendAmplitudeData("dashboard-date-end", { date: date });
    setEndDate(date.target.value);
  };

  const handleSetFilterBookmarked = (value: boolean) => {
    sendAmplitudeData("dashboard-filter-bookmarked", {
      value,
    });
    setFilterBookmarked(value);
  };

  const handleSetFilterSentimentValue = (value: SentimentValue) => {
    setFilterSentimentValue((prev) => {
      if (prev.includes(value)) {
        return prev.filter((v) => v !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const [showUploadCallModal, setShowUploadCallModal] = useState(false);

  const allowedUserIds = [
    "auth0|64f9d85a18765af7dd761f33",
    "auth0|64f9d88218765af7dd761f37",
    "auth0|64f9d7823d9e1162dc1173ee",
    "auth0|64f9d76618765af7dd761f22",
    "auth0|64f9d72f18765af7dd761f1f",
    "auth0|64f6ea7cc18541c842c72e46",
  ];

  function isValidDate(dateString) {
    const date = parseISO(dateString);
    return isValid(date);
  }

  const setLocationHash = (newHash: TabsKeys) => {
    navigate(`${location.pathname}${newHash}`);
  };

  const UsersPopoverMenu = ({ callerId }) => {
    if (!callerId) return null;

    let callerIdSentiments = [];

    return (
      <Popover id="users-table-popover-menu">
        <Popover.Body>
          <Form>
            <Form.Label>Select filters for {callerId}</Form.Label>
            {SentimentValueInfo.map(({ value, iconClass }, i) => {
              return (
                <Form.Check
                  type="switch"
                  label={<i className={iconClass}></i>}
                  key={"filter" + value.toLowerCase()}
                  onChange={() => {
                    if (callerIdSentiments.includes(value)) {
                      callerIdSentiments = callerIdSentiments.filter(
                        (v) => v !== value,
                      );
                    } else {
                      callerIdSentiments.push(value);
                    }
                  }}
                ></Form.Check>
              );
            })}
            <Button
              size="sm"
              variant="link"
              className="p-0 d-block"
              onClick={() => {
                setLocationHash(TAB_KEYS.phonecalls);
                setFilterSentimentValue(callerIdSentiments);
                setSearch(callerId);
              }}
            >
              Show In Calls Table
            </Button>
          </Form>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <Container>
      <Row className="my-5">
        <Col xs="12" className="mb-4">
          <h2>Dashboard</h2>
        </Col>
        <Col xs="2" lg="1">
          <ButtonGroup size="sm">
            <OverlayTrigger
              key="tab-users-button"
              overlay={<Tooltip>Show users</Tooltip>}
            >
              <Button
                active={currentTab === TAB_KEYS.users}
                onClick={() => setLocationHash(TAB_KEYS.users)}
              >
                <i className="bi-person"></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              key="tab-phonecalls-button"
              overlay={<Tooltip>Show phonecalls</Tooltip>}
            >
              <Button
                active={currentTab === TAB_KEYS.phonecalls}
                onClick={() => setLocationHash(TAB_KEYS.phonecalls)}
              >
                <i className="bi-telephone"></i>
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </Col>
        <FormGroup as={Col} xs="10" lg="3">
          <Form.Control
            value={search}
            size="sm"
            aria-label="case-search-input"
            aria-describedby="case-search-input"
            placeholder="Search name or phone number"
            onChange={(e) => handleSetSearch(e.target.value)}
          />
        </FormGroup>
        <Col xs="12" lg="4" className="d-flex my-3 my-lg-0">
          <InputGroup size="sm" className="me-2">
            <InputGroup.Text id="start-date">Start</InputGroup.Text>
            <Form.Control // prettier-ignore
              type="date"
              id="dateStart"
              value={startDate == null ? "" : startDate}
              aria-describedby="start-date"
              onChange={(e) => handleSetStartDate(e)}
            />
          </InputGroup>
          <InputGroup size="sm">
            <InputGroup.Text id="start-date">End</InputGroup.Text>
            <Form.Control // prettier-ignore
              type="date"
              id="dateEnd"
              value={endDate == null ? "" : endDate}
              onChange={(e) => handleSetEndDate(e)}
            />
          </InputGroup>
        </Col>
        {currentTab === TAB_KEYS.phonecalls && (
          <FormGroup
            as={Col}
            xs="12"
            lg="4"
            className="d-flex align-items-center justify-content-lg-end"
          >
            {!contactTypesLoading && (
              <OverlayTrigger
                key="filter-contact-type"
                overlay={<Tooltip>Contact Type</Tooltip>}
              >
                <Dropdown key="contact-type-dropdown">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    size="sm"
                    className="me-2"
                    id="contact-types-dropdown"
                  >
                    <i className="bi-person-lines-fill"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {contactTypesData?.contactTypes?.map(
                      ({ id, name }, index) => (
                        <Dropdown.Item
                          key={`contact-type-btn-${index}`}
                          active={id === currentContactType?.id}
                          as={Button}
                          onClick={() => {
                            setCurrentContactType(
                              currentContactType?.id === id
                                ? null
                                : { id, name },
                            );
                          }}
                        >
                          {name}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              key="filter-bookmarked"
              overlay={
                <Tooltip>
                  {filterBookmarked ? "Hide" : "Show"} Bookmarked
                </Tooltip>
              }
            >
              <Button
                variant="outline-secondary"
                size="sm"
                className="me-2"
                active={filterBookmarked}
                onClick={() => handleSetFilterBookmarked(!filterBookmarked)}
              >
                <i
                  className={
                    filterBookmarked ? "bi-bookmark-fill" : "bi-bookmark"
                  }
                ></i>
              </Button>
            </OverlayTrigger>
            <ButtonGroup size="sm" className="me-2">
              {SentimentValueInfo.map(({ value, iconClass }, i) => {
                const isFiltered = filterSentimentValue.includes(value);

                return (
                  <OverlayTrigger
                    key={"filter" + value.toLowerCase()}
                    overlay={
                      <Tooltip>
                        {isFiltered ? "Hide" : "Show"} {value?.toLowerCase()}{" "}
                        sentiment
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="outline-secondary"
                      active={isFiltered}
                      onClick={() => handleSetFilterSentimentValue(value)}
                    >
                      <i
                        className={isFiltered ? `${iconClass}-fill` : iconClass}
                      ></i>
                    </Button>
                  </OverlayTrigger>
                );
              })}
            </ButtonGroup>

            <OverlayTrigger overlay={<Tooltip>Upload Call</Tooltip>}>
              <Button
                disabled={!allowedUserIds.includes(auth.user?.sub)}
                onClick={() => setShowUploadCallModal(true)}
                variant="outline-secondary"
                size="sm"
              >
                <i className="bi-plus-lg"></i>
              </Button>
            </OverlayTrigger>
          </FormGroup>
        )}
        <Col xs="12">
          <Tab.Container defaultActiveKey={currentTab} activeKey={currentTab}>
            <Tab.Content className="my-5">
              <Tab.Pane eventKey={TAB_KEYS.users}>
                {UsersTable({
                  UsersPopoverMenu,
                  currentTab,
                  startDate,
                  endDate,
                  isValidDate,
                })}
              </Tab.Pane>
              <Tab.Pane eventKey={TAB_KEYS.phonecalls}>
                {CallsTable({
                  search,
                  startDate,
                  endDate,
                  filterBookmarked,
                  filterSentimentValue,
                  showUploadCallModal,
                  setShowUploadCallModal,
                  isValidDate,
                  currentContactType,
                  contactTypesData,
                })}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
}
