import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { CREATE_PHONECALL } from "../mutations/Cases";
import { useAuth0 } from "@auth0/auth0-react";
import config from "../config";
import { Upload } from "tus-js-client";

export function ModalUploadCall({ show, setShow, reload }) {
  const handleClose = () => setShow(false);
  const [createPhonecallFunction] = useMutation(CREATE_PHONECALL);

  const [callingUser, setCallingUser] = useState("");
  const [dialledUser, setDiallingUser] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [file, setFile] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleFileChanged = (event) => {
    setFile(event.target.files[0]);
  };
  const handleSave = async () => {
    console.log(file);
    createPhonecallFunction({
      variables: {
        callingAgent: callingUser,
        dialledAgent: dialledUser,
        eventDateTime: eventDate,
      },
    }).then(async (response) => {
      const id = response.data.addPhonecall.phonecall.id;
      const token = await getAccessTokenSilently();
      await tusUpload(id, file, token).then((result) => {
        reload();
        handleClose();
      });
    });
  };

  async function tusUpload(id: string, file: File, token: string) {
    console.log(`Uploading ${id}..`);
    const upload = new Upload(file, {
      endpoint: config.GQL_URL + "/files",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        phonecallId: id,
        filename: file.name,
        filetype: file.type,
      },
      onError: function (error) {
        console.log("Failed because: " + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        //const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
        //onUploadProgress(percentage);
      },
      onSuccess: function () {
        console.log("Download %s from %s", file.name, upload.url);
      },
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Phonecall</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formCaller">
            <Form.Label>Caller</Form.Label>
            <Form.Control
              type="text"
              placeholder="eg: John Smith"
              onChange={(e) => setCallingUser(e.target.value)}
            />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDialled">
            <Form.Label>Dialled</Form.Label>
            <Form.Control
              type="text"
              placeholder="eg: John Smith"
              onChange={(e) => setDiallingUser(e.target.value)}
            />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDate">
            <Form.Label>Event Date</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              onChange={(e) => setEventDate(e.target.value)}
            />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control type="file" onChange={handleFileChanged} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
