export type SentimentValue = "POSITIVE" | "NEGATIVE" | "NEUTRAL";

export const SentimentValueInfo: ReadonlyArray<{
  value: SentimentValue;
  iconClass: string;
  variant: string;
}> = [
  {
    value: "POSITIVE",
    iconClass: "bi-emoji-laughing",
    variant: "success",
  },
  {
    value: "NEUTRAL",
    iconClass: "bi-emoji-neutral",
    variant: "",
  },
  {
    value: "NEGATIVE",
    iconClass: "bi-emoji-frown",
    variant: "danger",
  },
] as const;

export const SentimentValueIcon = (
  sentimentValue: SentimentValue | undefined,
) => {
  const data = SentimentValueInfo.find((item) => item.value === sentimentValue);

  if (!data) return <></>;

  const { iconClass, variant } = data;

  return <i className={`${iconClass} ${variant ? "text-" + variant : ""}`}></i>;
};
