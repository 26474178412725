import Spinner from "react-bootstrap/Spinner";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { CREATE_PHONECALL_SECURE_STREAM } from "../mutations/Cases";
import { sendAmplitudeData } from "../services/amplitudeClient";

export function AudioPlayer({ phonecallId }) {
  const [createPhonecallSecureStreamFunction, { data, error, loading }] =
    useMutation(CREATE_PHONECALL_SECURE_STREAM);

  const handleOnPlay = () => {
    sendAmplitudeData("phonecall-play", { id: phonecallId });
  };

  const handleOnPause = () => {
    sendAmplitudeData("phonecall-pause", { id: phonecallId });
  };

  const handleOnSeek = () => {
    sendAmplitudeData("phonecall-seek", { id: phonecallId });
  };

  useEffect(() => {
    if (phonecallId == null) {
      //setStreamUrl(null)
      return;
    }

    createPhonecallSecureStreamFunction({ variables: { phonecallId } });
  }, [createPhonecallSecureStreamFunction, phonecallId]);

  //const [streamUrl, setStreamUrl] = useState(null);
  if (loading)
    return (
      <div className="d-flex align-items-center">
        <Spinner animation="border" role="status" className="me-3">
          <span className="visually-hidden">Loading audio</span>
        </Spinner>
        <span>Loading audio</span>
      </div>
    );

  if (error) return <div>Audio not available.</div>;

  return (
    <audio
      className="shadow rounded-5 w-100"
      src={data?.createPhonecallSecureStream.string}
      controls
      onPlay={handleOnPlay}
      onPause={handleOnPause}
      onSeeking={handleOnSeek}
    ></audio>
  );
}
