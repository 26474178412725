import gql from "graphql-tag";

export const GET_CASES = gql`
  query {
    cases(order: [{ createdDateTime: ASC }], first: 10) {
      edges {
        cursor
        node {
          id
          reference
          createdDateTime
          modifiedDateTime
          createdByUserId
          phonecalls {
            id
            reference
            number
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_CASE = gql`
  query GET_CASE($id: UUID!) {
    case(id: $id) {
      id
      reference
      phonecalls {
        id
        reference
        number
        createdDateTime
        sentiment
        status
        transcription
        keyFacts
        topics
      }
    }
  }
`;
