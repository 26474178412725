import Spinner from "react-bootstrap/Spinner";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Badge from "react-bootstrap/Badge";
import { useParams, NavLink, Link } from "react-router-dom";
import { GET_CASE } from "../queries/Cases";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { DELETE_PHONECALL } from "../mutations/Cases";
import { PHONECALL_UPDATES } from "../subscriptions/phonecall";
import { CallTabs } from "./CallTabs";
import { Stack } from "react-bootstrap";

export const Case = () => {
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { loading, data, refetch } = useQuery(GET_CASE, {
    variables: { id },
  });
  const [deletePhonecallFunction] = useMutation(DELETE_PHONECALL);

  useSubscription(PHONECALL_UPDATES);

  useEffect(() => {
    if (selectedRow) {
      //fetchData(selectedRow.id); // Example function for fetching data
    }
  }, [selectedRow]);

  const handleRowSelection = (target: EventTarget, id) => {
    console.log(target);
    setSelectedRow(id);
    var phonecall = data.case.phonecalls.find((x) => x.id === id);
    setSelectedRowData(phonecall);
  };

  const handleDelete = async (_phonecallId) => {
    await deletePhonecallFunction({
      variables: {
        caseId: id,
        phonecallId: _phonecallId,
      },
    });
    await refetch();
  };

  return (
    <Container className="my-5">
      <Row className="mb-3">
        <Col xs="12">
          <Breadcrumb>
            <NavLink to="/" className="breadcrumb-item">
              Home
            </NavLink>
            <NavLink to="/dashboard" className="breadcrumb-item">
              Dashboard
            </NavLink>
            <Breadcrumb.Item active>{data?.case.reference}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col xs="12">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {!loading && (
        <Row>
          <Col xs="12" lg="6">
            <Row>
              <Col xs="12" className="mb-4"></Col>
              {/*<Col xs="12">*/}
              {/*  <InputGroup className="mb-4 shadow rounded">*/}
              {/*    <Form.Control*/}
              {/*      placeholder="Search phonecalls e.g. APP00123"*/}
              {/*      aria-label="phone-search-input"*/}
              {/*      aria-describedby="phone-search-input"*/}
              {/*      disabled*/}
              {/*    />*/}
              {/*    <Button variant="primary" id="phone-search-button" disabled>*/}
              {/*      search*/}
              {/*    </Button>*/}
              {/*  </InputGroup>*/}
              {/*</Col>*/}
            </Row>
            <Table striped borderless hover responsive="xs shadow">
              <thead>
                <tr>
                  <th>Call Reference</th>
                  {/*<th>Date Added</th>*/}
                  <th>Status</th>
                  <th>Topics</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.case.phonecalls.map((item) => (
                  <tr
                    key={item.id}
                    onClick={(event) =>
                      handleRowSelection(event.relatedTarget, item.id)
                    }
                    style={{ cursor: "pointer" }}
                    className={item.id === selectedRow ? "table-primary" : ""}
                  >
                    <td className="text-break">{item.reference}</td>
                    {/*<td>*/}
                    {/*  <DateTime value={item.createdDateTime} />*/}
                    {/*</td>*/}
                    <td>
                      <Badge
                        bg={
                          item.status === "COMPLETE" ? "success" : "secondary"
                        }
                        className="text-lowercase rounded-5"
                      >
                        {item.status}
                      </Badge>
                    </td>
                    <td>
                      <Stack direction="horizontal" gap={2} className="pt-1">
                        {item?.topics?.map((topic, index) => (
                          <Badge bg="warning" key={index}>
                            {topic}
                          </Badge>
                        ))}
                      </Stack>
                    </td>
                    <td>
                      <ButtonGroup size="sm">
                        <Link
                          className="btn btn-primary"
                          to={`/call/${item.id}`}
                          state={{
                            data: item,
                            id,
                            caseReference: data?.case.reference,
                          }}
                        >
                          View
                        </Link>
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col xs="12" lg="6">
            <CallTabs data={selectedRowData} />
          </Col>
        </Row>
      )}
    </Container>
  );
};
