import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Badge from "react-bootstrap/Badge";
import DateTime from "./DateTime";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { CREATE_TRANSCRIPTION, DELETE_PHONECALL } from "../mutations/Cases";
import { AudioPlayer } from "./AudioPlayer";
import {
  CREATE_PHONECALL_BOOKMARK,
  DELETE_PHONECALL_BOOKMARK,
  GET_CALL,
} from "../queries/Calls";
import { Accordion, Form } from "react-bootstrap";
import { PHONECALL_UPDATES } from "../subscriptions/phonecall";
import { sendAmplitudeData } from "../services/amplitudeClient";

export const Call = () => {
  const { state } = useLocation();
  const { id } = state;
  const navigate = useNavigate();
  const { loading, data, refetch } = useQuery(GET_CALL, {
    variables: { id },
  });
  const [deletePhonecallFunction] = useMutation(DELETE_PHONECALL);
  const [createTranscriptionFunction] = useMutation(CREATE_TRANSCRIPTION);
  const [createBookmarkFunction] = useMutation(CREATE_PHONECALL_BOOKMARK);
  const [deleteBookmarkFunction] = useMutation(DELETE_PHONECALL_BOOKMARK);
  const isCheckboxChecked = data?.phonecall.follows.length > 0;

  useSubscription(PHONECALL_UPDATES);

  const handleDelete = async (phonecallId) => {
    sendAmplitudeData("phonecall-delete", { id: phonecallId });
    await deletePhonecallFunction({
      variables: {
        phonecallId,
      },
    });
    navigate(`/dashboard`);
  };

  const handleCreateTranscription = async (phonecallId) => {
    sendAmplitudeData("phonecall-transcribe", { id: phonecallId });
    await createTranscriptionFunction({
      variables: {
        id: phonecallId,
      },
    });
  };

  const handleSetBookmarked = async (phonecallId: string, checked: boolean) => {
    if (!checked) {
      sendAmplitudeData("phonecall-bookmark-delete", { id: phonecallId });
      await deleteBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id,
            __typename: "UserPhonecallFollow",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
      await refetch();
    } else {
      sendAmplitudeData("phonecall-bookmark-create", { id: phonecallId });
      await createBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
      });
      await refetch();
    }
    //setBookmarked(checked);
  };

  return (
    <Container className="my-5">
      <Row className="mb-3">
        <Col xs="12">
          <Breadcrumb>
            <NavLink to="/dashboard" className="breadcrumb-item">
              Dashboard
            </NavLink>
            <Breadcrumb.Item active>Call</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {!loading && (
        <Row className="mb-3">
          <Col lg="6">
            <dl className="row">
              <dt className="col-sm-3">Caller</dt>
              <dd className="col-sm-9">
                {data?.phonecall.callingAgent ||
                  data?.phonecall.callingPartyNumber}
              </dd>
              <dt className="col-sm-3">Dialled</dt>
              <dd className="col-sm-9">
                {data?.phonecall.dialledAgent ||
                  data?.phonecall.dialledPartyNumber}
              </dd>
              <dt className="col-sm-3">When</dt>
              <dd className="col-sm-9">
                {data ? (
                  <DateTime value={data?.phonecall.eventDateTime} />
                ) : (
                  "-"
                )}
              </dd>
              <dt className="col-sm-3">Status</dt>
              <dd className="col-sm-9">
                <Badge
                  bg={
                    data?.phonecall.status === "COMPLETE"
                      ? "success"
                      : "secondary"
                  }
                  className="text-lowercase rounded-5"
                >
                  {data?.phonecall.status}
                </Badge>
              </dd>
              <dt className="col-sm-3">Bookmark</dt>
              <dd className="col-sm-9">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  checked={isCheckboxChecked}
                  onChange={(e) =>
                    handleSetBookmarked(data?.phonecall.id, e.target.checked)
                  }
                />
              </dd>
              {/*<dt className="col-sm-3">Topics</dt>*/}
              {/*<dd className="col-sm-9">*/}
              {/*  <Stack direction="horizontal" gap={2} className="pt-1">*/}
              {/*    {data?.phonecall.topics?.map((topic, index) => (*/}
              {/*      <Badge bg="warning" key={index}>*/}
              {/*        {topic}*/}
              {/*      </Badge>*/}
              {/*      ))}*/}
              {/*  </Stack>*/}
              {/*</dd>*/}
              <dt className="col-sm-3">Actions</dt>
              <dd className="col-sm-9">
                {data?.phonecall.id && (
                  <ButtonGroup size="sm">
                    <Button
                      variant="primary"
                      disabled={data?.phonecall.transcription}
                      onClick={() =>
                        handleCreateTranscription(data?.phonecall.id)
                      }
                    >
                      Transcribe
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(data?.phonecall.id)}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                )}
              </dd>
            </dl>
          </Col>
          <Col lg="6" className={"text-end"}></Col>
          <br />
          <Col xs="12" className="mb-4" style={{ minHeight: 60 }}>
            <AudioPlayer phonecallId={data?.phonecall.id} />
          </Col>
          <Col xs="12" lg="12">
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Sentiment</Accordion.Header>
                <Accordion.Body style={{ whiteSpace: "pre-wrap" }}>
                  {data.phonecall.sentiment ??
                    "Please Transcribe the call to enable Sentiment analysis"}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Key Facts</Accordion.Header>
                <Accordion.Body style={{ whiteSpace: "pre-wrap" }}>
                  {data.phonecall.keyFacts ??
                    "Please Transcribe the call to enable Key Fact analysis"}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Transcript</Accordion.Header>
                <Accordion.Body style={{ whiteSpace: "pre-wrap" }}>
                  {data.phonecall.transcription ??
                    "Please Transcribe the call to enable Transcription"}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      )}
    </Container>
  );
};
