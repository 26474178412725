import gql from "graphql-tag";

export const CREATE_PHONECALL = gql`
  mutation ADD_PHONECALL(
    $callingAgent: String!
    $dialledAgent: String!
    $eventDateTime: DateTime!
  ) {
    addPhonecall(
      input: {
        callingAgent: $callingAgent
        dialledAgent: $dialledAgent
        eventDateTime: $eventDateTime
      }
    ) {
      phonecall {
        id
        reference
        createdDateTime
        modifiedDateTime
        createdByUserId
      }
    }
  }
`;

export const CREATE_TRANSCRIPTION = gql`
  mutation CREATE_TRANSCRIPTION($id: UUID!) {
    createPhonecallTranscription(input: { phonecallId: $id }) {
      transcriptionJob {
        id
      }
    }
  }
`;

export const DELETE_PHONECALL = gql`
  mutation DELETE_PHONECALL($phonecallId: UUID!) {
    deletePhonecall(input: { phonecallId: $phonecallId }) {
      phonecall {
        id
      }
    }
  }
`;

export const CREATE_PHONECALL_SECURE_STREAM = gql`
  mutation CREATE_PHONECALL_SECURE_STREAM($phonecallId: UUID!) {
    createPhonecallSecureStream(input: { phonecallId: $phonecallId }) {
      string
    }
  }
`;

export const CREATE_CASE = gql`
  mutation CREATE_CASE($reference: String!) {
    createCase(input: { reference: $reference }) {
      case {
        id
        reference
        createdDateTime
        createdByUserId
      }
      errors {
        __typename
      }
    }
  }
`;

export const DELETE_CASE = gql`
  mutation DELETE_CASE($id: UUID!) {
    deleteCase(input: { id: $id }) {
      boolean
    }
  }
`;
