import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Contact from "./components/Contact";
import { AuthProvider, RequireAuth } from "./contexts/Auth";
import { Auth0Provider } from "@auth0/auth0-react";
import Dashboard from "./components/Dashboard";
import { Case } from "./components/Case";
import { Call } from "./components/Call";
import SentimentReport from "./components/SentimentReport";
import { initAmplitude } from "./services/amplitudeClient";
import AuthorizedApolloProvider from "./contexts/AuthorizedApolloProvider";
import { Footer } from "./components/Footer";

initAmplitude();

function App() {
  return (
    <BrowserRouter>
      <Auth0Provider
        domain="callshield.uk.auth0.com"
        clientId="CzLLa5IDHx9bSghr1D3XW0nh6hsntMsW"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://api.callshield.ai",
        }}
      >
        <AuthorizedApolloProvider>
          <AuthProvider>
            <Header />
            <Routes>
              <Route index element={<Home />} />
              <Route path="contact" element={<Contact />} />
              <Route
                path="/dashboard"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="/case/:id"
                element={
                  <RequireAuth>
                    <Case />
                  </RequireAuth>
                }
              />
              <Route
                path="/call/:id"
                element={
                  <RequireAuth>
                    <Call />
                  </RequireAuth>
                }
              />
              <Route
                path="/report/sentiment"
                element={
                  <RequireAuth>
                    <SentimentReport />
                  </RequireAuth>
                }
              />
              {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
              {/*<Route path="*" element={<NoMatch />} />*/}
            </Routes>
            <Footer />
          </AuthProvider>
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
}

export default App;
