import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Stack from "react-bootstrap/Stack";
import { Badge } from "react-bootstrap";
import { ModalUploadCall } from "./ModalUploadCall";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import DateTime from "./DateTime";
import { useEffect, useState } from "react";
import { GET_CALLS, GET_FOLLOWED_CALLS } from "../queries/Calls";
import TimeSpan from "./TimeSpan";
import { sendAmplitudeData } from "../services/amplitudeClient";
import { SentimentValueIcon } from "./SentimentValueIcon";
import { useLocalStorage } from "usehooks-ts";

export function CallsTable({
  search,
  startDate,
  endDate,
  filterBookmarked,
  filterSentimentValue,
  showUploadCallModal,
  setShowUploadCallModal,
  isValidDate,
  currentContactType,
  contactTypesData,
}) {
  const [paginationInput, setPaginationInput] = useLocalStorage("pagination", {
    first: 10,
    after: null,
    last: null,
    before: null,
  });

  const [contactTypeNames, setContactTypeNames] = useState({});

  useEffect(() => {
    setContactTypeNames(() => {
      const data = contactTypesData?.contactTypes || [];

      return data.reduce((acc, item) => {
        acc[item.id] = item.name;

        return acc;
      }, {});
    });
  }, [currentContactType, contactTypesData]);

  const startPage = () => {
    sendAmplitudeData("dashboard-page-start", {});
    setPaginationInput({
      first: 10,
      after: null,
      last: null,
      before: null,
    });
  };

  const nextPage = () => {
    sendAmplitudeData("dashboard-page-next", {});
    setPaginationInput({
      first: 10,
      after: data?.phonecalls.pageInfo.endCursor,
      last: null,
      before: null,
    });
  };

  const prevPage = () => {
    sendAmplitudeData("dashboard-page-previous", {});
    setPaginationInput({
      first: null,
      after: null,
      last: 10,
      before: data?.phonecalls.pageInfo.startCursor,
    });
  };

  const { loading, data, refetch } = useQuery(
    filterBookmarked ? GET_FOLLOWED_CALLS : GET_CALLS,
    {
      variables: {
        ...paginationInput,
        search: search,
        startDate: isValidDate(startDate)
          ? startDate
          : new Date(0).toISOString(),
        endDate: isValidDate(endDate)
          ? endDate
          : new Date(9640000000000).toISOString(),
        filterSentiment:
          filterSentimentValue.length === 0
            ? ["POSITIVE", "NEUTRAL", "NEGATIVE", null]
            : filterSentimentValue,
        filterContactType: currentContactType
          ? currentContactType.id
          : [
              ...(contactTypesData?.contactTypes?.map(
                (contact) => contact.id,
              ) || []),
              null,
            ],
      },
    },
  );

  return (
    <>
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!loading && (
        <>
          <Table striped hover borderless className="shadow mb-4">
            <thead className="rounded-top">
              <tr>
                <th>Caller</th>
                {/*<th>Calling Number</th>*/}
                <th>Dialled</th>
                <th>Contact Type</th>
                <th>Call Direction</th>
                <th>Duration</th>
                {/*<th>Dialled Party Number</th>*/}
                <th>When</th>
                <th style={{ width: 200 }}>Status</th>
                <th>Monitors</th>
                <th>Topics</th>
                <th>Sentiment</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.phonecalls?.nodes?.map((item) => (
                <tr key={item.id}>
                  <td>{item.callingAgent || item.callingPartyNumber}</td>
                  {/*<td>{item.callingPartyNumber}</td>*/}
                  <td>{item.dialledAgent || item.dialledPartyNumber}</td>
                  {/*<td>{item.dialledPartyNumber}</td>*/}
                  <td>{contactTypeNames[item.linkedContactTypeId] || ""}</td>
                  <td>{item.callDirection.toLowerCase()}</td>
                  <td>
                    <TimeSpan value={item.callDuration} />
                  </td>
                  <td>
                    <DateTime value={item.eventDateTime} />
                  </td>
                  <td>
                    <Badge
                      bg={item.status === "COMPLETE" ? "success" : "secondary"}
                      className="text-lowercase rounded-5"
                    >
                      {item?.status}
                    </Badge>
                  </td>
                  <td>
                    <Stack direction="horizontal" gap={2} className="pt-1">
                      {item?.sentenceMonitors?.map(
                        (monitor, index) =>
                          monitor.isMatched && (
                            <Badge bg="success" key={index}>
                              {monitor.monitorTopic.label}
                            </Badge>
                          ),
                      )}
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="horizontal" gap={2} className="pt-1">
                      {item?.topics?.map((topic, index) => (
                        <Badge bg="warning" key={index}>
                          {topic}
                        </Badge>
                      ))}
                    </Stack>
                  </td>
                  <td>{SentimentValueIcon(item?.sentimentValue)}</td>
                  <td className="position-relative">
                    <ButtonGroup aria-label="Basic example" size="sm">
                      {
                        <Link
                          className="btn btn-primary"
                          to={`/call/${item.id}`}
                          state={{
                            data: item,
                            id: item.id,
                          }}
                        >
                          View
                        </Link>
                      }
                      {/*<Button variant="danger" onClick={()=>handleDeleteClick(item.node.id)}>*/}
                      {/*  Delete*/}
                      {/*</Button>*/}
                    </ButtonGroup>
                    {item?.follows?.length > 0 && (
                      <i
                        className="bi-bookmark-fill position-absolute"
                        style={{ right: -8 }}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="text-center">
            <ButtonGroup size="sm">
              <Button
                disabled={!data?.phonecalls.pageInfo.hasPreviousPage}
                onClick={startPage}
              >
                Start
              </Button>
              <Button
                disabled={!data?.phonecalls.pageInfo.hasPreviousPage}
                onClick={prevPage}
              >
                Previous
              </Button>
              <Button
                disabled={!data?.phonecalls.pageInfo.hasNextPage}
                onClick={nextPage}
              >
                Next
              </Button>
            </ButtonGroup>
          </div>
          <ModalUploadCall
            show={showUploadCallModal}
            setShow={setShowUploadCallModal}
            reload={refetch}
          />
        </>
      )}
    </>
  );
}
