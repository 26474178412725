import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export function CallTabs({ data }) {
  return (
    <Tabs
      defaultActiveKey="sentiment"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="sentiment" title="Sentiment" key="title">
        <div style={{ whiteSpace: "pre-wrap" }}>
          {data?.sentiment ?? "Select a call"}
        </div>
      </Tab>
      <Tab eventKey="keyfacts" title="Key Facts" key="key-facts">
        <div style={{ whiteSpace: "pre-wrap" }}>
          {data?.keyFacts ?? "Select a call"}
        </div>
      </Tab>
      <Tab eventKey="transcription" title="Transcript" key="transcript">
        <div style={{ whiteSpace: "pre-wrap" }}>
          {data?.transcription ?? "Select a call"}
        </div>
      </Tab>
    </Tabs>
  );
}
