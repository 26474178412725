import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DotLottiePlayer } from "@dotlottie/react-player";
import "@dotlottie/react-player/dist/index.css";

export default function Home() {
  return (
    <Container>
      <Row className="my-5">
        <Col className="text-center" lg="12">
          <h1 className="display-4 fw-bold mb-3">Superpowers for Compliance</h1>
          <p className="mx-auto lead" style={{ maxWidth: 558 }}>
            Effortlessly turn customer phone calls and video meetings in to
            actionable insights. Transcribe, Understand and Act.
          </p>
        </Col>
        <Col lg="12">
          {/* src="https://lottie.host/22eaf9a5-6bd2-460d-b611-93b91aa5ebc2/eS4n6hFXBF.lottie" */}
          <DotLottiePlayer
            className="mx-auto"
            style={{ maxWidth: 700 }}
            src="/animations/1.lottie"
            autoplay
            loop
          ></DotLottiePlayer>
          <hr style={{ maxWidth: 600 }} className="mx-auto mb-0" />
        </Col>
      </Row>

      <Row className="flex-lg-row-reverse align-items-center">
        <Col col="12" lg="6">
          <DotLottiePlayer
            className="mx-auto"
            style={{ maxWidth: 400 }}
            src="/animations/2.lottie"
            autoplay
            loop
          ></DotLottiePlayer>
        </Col>
        <Col col="12" lg="4">
          <h2 className="display-5 fw-bold lh-1 mb-3">Transcribe</h2>
          <p className="lead">
            Quickly connect to your phone system for access to transcribed
            conversations and call participants. Invite to your Google Meet or
            Teams calls. Secure call and meeting recording included at no extra
            cost.
          </p>
        </Col>
      </Row>

      <Row className="flex-lg-row-reverse align-items-center">
        <Col col="12" lg="6" className="order-2 order-lg-1">
          <h2 className="display-5 fw-bold lh-1 mb-3">Understand</h2>
          <p className="lead">
            Auto-summarise meetings and video calls. Extract key facts and
            information. Gain insights with sentiment analysis.
          </p>
        </Col>
        <Col col="12" lg="6" className="order-1 order-lg-2">
          <DotLottiePlayer
            className="mx-auto"
            style={{ maxWidth: 400 }}
            src="/animations/3.lottie"
            autoplay
            loop
          ></DotLottiePlayer>
        </Col>
      </Row>

      <Row className="flex-lg-row-reverse align-items-center">
        <Col col="12" lg="4">
          <DotLottiePlayer
            className="mx-auto"
            style={{ maxWidth: 400 }}
            src="/animations/4.lottie"
            autoplay
            loop
          ></DotLottiePlayer>
        </Col>
        <Col col="12" lg="6">
          <h2 className="display-5 fw-bold lh-1 mb-3">Act</h2>
          <p className="lead" style={{ maxWidth: 500 }}>
            Vector search allows instant screening of all calls and meetings.
            Identify if staff are going off script. Understand when customers
            are happy or have complaints. Get notifications and stay one step
            ahead of fraud.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
